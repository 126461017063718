import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function HeroTitle({ text, className }) {
  return (
    <h1
      className={clsx(
        'font-semiBold',
        className,
        'text-4xl md:text-6xl text-center md:text-left max-w-3xl my-6 md:leading-tight',
      )}
      dangerouslySetInnerHTML={{ __html: text }}
    ></h1>
  );
}

HeroTitle.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

HeroTitle.defaultProps = {
  className: '',
};

export default HeroTitle;
